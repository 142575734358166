import React, {Key} from "react";
import {Button, Col, Modal, Pagination, Row, Tooltip} from "antd";
import _ from "underscore";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import IViewItem from "model/interface/dataStorage/view/IViewItem";
import IField, {
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED,
    FIELD_MODE_SCALAR,
    FIELD_TYPE
} from "model/interface/dataStorage/IField";
import FilterBuilder from "utils/FilterBuilder";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import IRestServiceFilters, {isFilterNested} from "../../../model/interface/api/IRestServiceFilters";
import ViewAction from "./ViewAction";
import {TablePaginationConfig} from "antd/es";
import {ColumnGroupType, SorterResult} from "antd/es/table/interface";
import IRestServiceOrders from "../../../model/interface/api/IRestServiceOrders";
import {ColumnsType, ColumnType} from "antd/lib/table/interface";
import {IFilterBaseValue} from "../../../utils/Filter/IFilterBase";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import DataStorageHelper from "../../../utils/DataStorageHelper";
import ActionsService, {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {RefreshOutline} from "react-ionicons";
import IPresenter from "../../../model/interface/dataStorage/IPresenter";
import {Prompt} from "react-router-dom"
import ViewUnit, {IBaseViewProps} from "./ViewUnit";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import Table from "../../shared/Table/Table";
import IViewTableItemSettings from "../../../model/interface/dataStorage/view/table/IViewTableItemSettings";
import ViewTableExportButton from "./table/ViewTableExportButton";
import FormInlineEdit from "../form/FormInlineEdit";
import FiltersService from "../../../model/service/dataStorage/FIltersService";
import {ActionButtonExecuteType} from "../action/ActionButton";
import IViewAction from "../../../model/interface/dataStorage/view/IViewAction";
import ViewEditButton from "./ViewEditButton";
import ViewPersonalEditButton from "./ViewPersonalEditButton";
import IRestServiceAggregations from "../../../model/interface/api/IRestServiceAggregations";
import {RestAggregation} from "../../../model/interface/api/IRestServiceCollectionResponse";
import Aggregations from "./statistics/Aggregations";
import ViewTitle from "./ViewTitle";
import ViewUnitsService from "../../../model/service/dataStorage/ViewUnitsService";
import ViewSchemeActions from "./ViewSchemeActions";
import SortSettings from "./settings/SortSettings";
import ViewTableItemsSettings from "./table/ViewTableItemsSettings";
import IScreen from "../../../model/interface/ui/IScreen";
import Utils from "../../../utils";
import ViewFiltersInfo from "./tools/ViewFiltersInfo";

interface IState {
    selectedRowKeys: Key[],
    selectedResources: IRestResource[]
    results: Array<IRestResource>,
    aggregations?: RestAggregation[]
    page: number,
    count: number,
    totalCount: number,
    pageSize: number,
    loading: boolean,
    filters: IRestServiceFilters,
    customFilters?: IRestServiceFilters,
    order: IRestServiceOrders
    timeouts: { [name: string]: number }
    updating: { [name: string]: boolean | undefined },
    inlineEditedResources: IRestResource[],
    screen: IScreen
}

interface IProps extends IBaseViewProps {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findServiceByClassName: (name: string) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
    user: IUser,
    allowSettings?: boolean,
    allowExport?: boolean,
    title?: string,
    titleLevel?: 1 | 2 | 3 | 4 | 5
}

type Column = IViewItem & IViewTableItemSettings & { fieldObject: IField }

class ViewTable extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            results: [],
            filters: {},
            page: 1,
            count: 0,
            totalCount: 0,
            pageSize: 20,
            loading: false,
            order: SortSettings.buildSortSettings(props.settings, props.viewUnit, this.getContentType()),
            selectedRowKeys: [],
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName],
            timeouts: {},
            updating: {},
            ...this.presetState(),
            selectedResources: [],
            inlineEditedResources: [],
            screen: Utils.getScreenInfo()
        }
    }

    presetState() {
        const {settings, state, viewUnit} = this.props
        let previousState: any | IState = {}
        if (settings.tablePageSave && state.tablePage) {
            previousState.page = state.tablePage
        }
        if (settings.tableFilterSave && state.tableFilter) {
            previousState.filters = Object.fromEntries(
                state.tableFilter.filter(filter => viewUnit.items.find(viewItem => {
                    const field = _.findWhere(this.getService().getFields(), {uuid: viewItem.field});
                    return "field" in filter && field?.name === filter.field
                }))
                    .map((filter, index) => [index + '-previousFilter', filter]))
        }
        if (settings.tableSortSave && state.tableSort) {
            previousState.order = {
                ...Object.fromEntries(
                    state.tableSort.map((sort, index) => [index + '-previousSort', sort])),
                ...SortSettings.buildSortSettings(settings, viewUnit, this.getContentType())
            }
        }
        if (settings.tablePageSize) {
            previousState.pageSize = settings.tablePageSize
        }
        return previousState
    }

    componentDidMount() {
        this.load().then()
    }

    componentWillUnmount() {
        window.onbeforeunload = null
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {viewUnit, reload, settings} = this.props
        if (this.state.inlineEditedResources?.length > 0 && viewUnit.options.saveInlineEditingGloballyWarning !== false) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
        if (prevProps.reload !== reload && reload) {
            this.reload().then()
        }
        if (JSON.stringify(settings) !== JSON.stringify(prevProps.settings)) {
            this.setState(state => ({
                ...state, ...this.presetState(),
                page: settings.tablePageSize !== prevProps.settings.tablePageSize ? 1 : state.page
            }), settings.tablePageSize !== prevProps.settings.tablePageSize ? this.reload : undefined)
        }
    }

    onSelectRow(selectedRowKeys: Key[]): void {
        this.setState({selectedRowKeys, selectedResources: this.getSelectedResources(selectedRowKeys)});
    }

    load(ignoreCache?: boolean): Promise<void> {
        if (ignoreCache === undefined) {
            ignoreCache = false
        }
        const contentType = this.getContentType()
        const {findServiceByContentType, viewUnit} = this.props
        const service = findServiceByContentType(contentType)
        const {page, pageSize, filters, customFilters, order} = this.state
        let fixedFilters = FiltersService.makeFixed({...this.props.filters, ...customFilters})

        let aggregations: IRestServiceAggregations = {}
        if (viewUnit.options?.aggregations) {
            Object.entries(viewUnit.options.aggregations).forEach(([uuid, item]) => {
                const field = _.findWhere(service.getFields(), {name: item.field});
                if (field) {
                    aggregations[uuid] = {
                        field: field.name,
                        type: item.type
                    }
                }
            })
        }

        const options: IRestServiceOptions = {
            page,
            limit: pageSize,
            filters: {...filters, ...fixedFilters},
            view: viewUnit.view,
            aggregations
        }

        if (order) {
            options.order = order
        }
        if (ignoreCache) {
            options.cache = false
        }
        this.setState({loading: true})
        return service.collectionList(options).then((result) => {
            return new Promise(resolve => {
                this.setState({
                    results: result.results,
                    count: result.count,
                    totalCount: result.totalCount,
                    loading: false,
                    aggregations: result.aggregations
                }, () => {
                    resolve();
                })
            })
        })
    }

    getFilters() {
        const {filters, customFilters} = this.state
        return {...filters, ...this.props.filters, ...customFilters};
    }

    reload() {
        return this.load(true);
    }

    onFinish = (result?: IActionResult): Promise<void> => {
        return new Promise<void>(resolve => {
            this.setState({
                selectedRowKeys: [],
                selectedResources: [],
                inlineEditedResources: []
            }, () => {
                resolve()
            })
        }).then(() => {
            if (this.props.onFinishAction) {
                return this.props.onFinishAction(result)
            } else {
                return this.reload()
            }
        })
    }

    getPayload() {
        let payload = {} as any;
        payload.route = this.props.extractRouteParametersFromUrl(this.props.history.location.pathname)
        return payload;
    }

    buildColumnStringValue(item: IViewItem, row: IRestResource) {
        const field: IField = this.findField(item)
        if ([FIELD_MODE_SCALAR, FIELD_MODE_COMPOSITE, FIELD_MODE_COMPUTED].includes(field.mode || '')) {
            return {
                value: row[field.name],
                label: row[field.name]
            }
        } else if (field.mode === 'relation') {
            const targetEntity = field.targetEntity
            if (!targetEntity) {
                throw new Error('Content type is not set')
            }
            let service: IRepositoryService = this.props.findServiceByClassName(targetEntity)
            if (!service) {
                throw new Error('Service for "' + field.targetEntity + '" is missing')
            }
            const relatedEntity = row[field.name] as IRestResource
            return {
                value: row[field.name]?.id || null,
                label: relatedEntity ? service.getStringValue(relatedEntity) : ''
            }
        } else {
            throw new Error('Invalid item mode: ' + field.mode)
        }
    }

    saveInlineEditedResource = (id: number | undefined, field: string, value: any) => {
        this.props.viewUnit.options.saveInlineEditingGlobally && this.setState(state => ({
            inlineEditedResources: [...state.inlineEditedResources.filter(i => i.id !== id), {
                ...state.inlineEditedResources.find(i => i.id === id) || {
                    id: id,
                    [field]: value
                }, [field]: value
            }]
        }))
    }

    buildColumnValue(field: IField, item: IViewItem, row: IRestResource) {
        const {history, match, viewUnit} = this.props
        if (item.options && item.options.inlineEdit) {
            const dataActionUuid = item.options && item.options.inPlaceDataAction ? item.options.inPlaceDataAction.toString() : undefined
            const actionUuid = item.options && item.options.inPlaceAction ? item.options.inPlaceAction.toString() : undefined
            const action = actionUuid ? this.getContentType().actions.find(action => action.uuid === actionUuid) : undefined
            const type = item.options.inPlaceType?.toString()
            if (FormInlineEdit.isAllowed(row, action)) {
                return (
                    <FormInlineEdit resource={row}
                                    reload={this.state.loading && !viewUnit.options.saveInlineEditingGlobally}
                                    type={type}
                                    fieldOptions={item.options.inPlaceFieldOptions || field.options}
                                    field={field}
                                    onUpdate={value => this.saveInlineEditedResource(row.id, field.name, value)}
                                    contentType={this.getContentType()}
                                    doAction={dataActionUuid} match={match} history={history}
                                    onFinish={this.onFinish}
                                    noAction={viewUnit.options.saveInlineEditingGlobally}
                    />
                )
            }
        }
        return this.buildValueByPresenter(field, item, row);
    }

    buildValueByPresenter(field: IField, item: IViewItem, row: IRestResource) {
        let presenter = this.getPresenter(field, item);
        return DataStorageHelper.buildFieldValue(field, row, this.props.history, presenter)
    }

    getPresenter(field: IField, item: IViewItem) {
        let presenter: null | IPresenter = null
        if (field.mode === 'relation') {
            const service = this.props.findServiceByClassName(field.targetEntity!)
            if (item.presenter) {
                presenter = service.getPresenter(item.presenter)
            }
            if (!presenter) {
                presenter = service.getDefaultPresenter()
            }
        }
        if (!presenter) {
            presenter = {
                type: 'label',
                name: 'default',
                label: 'Výchozí',
                options: {
                    label: "#" + field.name
                }
            } as IPresenter
        }
        presenter.options = {...item.options?.fieldOptions, ...item.options, ...presenter.options}
        return presenter
    }

    clearFilters = () => {
        this.setState({filters: {}}, () => {
            this.saveState()
            this.load().then()
        })
    }

    getContentType() {
        return this.props.findContentType(this.props.viewUnit.contentTypes[0])
    }


    buildColumns() {
        const {viewUnit, settings, history, match, standalone} = this.props
        const contentType = this.getContentType()
        const preActions = viewUnit.entryActions.filter((viewAction) => viewAction.weight < 0)
        const postActions = viewUnit.entryActions.filter((viewAction) => viewAction.weight >= 0)

        let tableColumns: ColumnsType<IRestResource> = []

        let items = this.buildViewItems(settings.tableItems);
        let first = true
        if (preActions.length > 0) {
            tableColumns.push({
                title: (
                    <></>
                ),
                key: 'actions',
                dataIndex: 'actions',
                render: (label: any, elm: IRestResource) => (
                    <Row justify={"start"} gutter={[6, 6]} wrap={false} className={(standalone && first) ? "pl-4" : ""}>
                        {
                            preActions
                                .filter(viewAction => {
                                    const action = _.findWhere(contentType.actions, {uuid: viewAction.action})
                                    return action && elm._permissions[action.name]
                                })
                                .map((action) => (
                                    <Col key={action.uuid}>
                                        <ViewAction
                                            history={history}
                                            match={match}
                                            resources={[elm]}
                                            onClick={executeAction => this.executeAction(executeAction, action, [elm])}
                                            key={action.uuid}
                                            action={action}
                                            payload={this.getPayload()}
                                            onFinish={this.onFinish}
                                        />
                                    </Col>
                                ))
                        }
                    </Row>
                )
            })
            first = false
        }

        _.each(items, item => {
            const field = item.fieldObject
            if (field && item.enabled) {
                let columns = this.buildColumn(item, field, first);
                tableColumns.push(...columns)
            }
            first = false
        })
        if (postActions.length > 0) {
            tableColumns.push({
                title: false,
                key: 'actions',
                dataIndex: 'actions',
                render: (label: any, elm: IRestResource) => (
                    <Row justify={"end"} gutter={[6, 6]} wrap={false}>
                        {postActions.filter(viewAction => {
                            const action = _.findWhere(contentType.actions, {uuid: viewAction.action})
                            return action && ActionsService.isResourceAllowed(elm, action)
                        }).map((action) => (
                            <Col>
                                <ViewAction
                                    history={history}
                                    match={match}
                                    resources={[elm]}
                                    onClick={executeAction => this.executeAction(executeAction, action, [elm])}
                                    key={action.uuid}
                                    action={action}
                                    payload={this.getPayload()}
                                    onFinish={this.onFinish}
                                />
                            </Col>
                        ))
                        }
                    </Row>
                )
            })
        }

        return tableColumns
    }

    buildColumn(item: Column, field: IField, first: boolean): (ColumnType<IRestResource> | ColumnGroupType<IRestResource>)[] {
        const {viewUnit, settings, standalone} = this.props
        const {filters, results} = this.state
        const contentType = this.getContentType()

        const defaultSort = this.getDefaultSort(viewUnit, contentType, field)
        const {noLabel, noLineBreak, subColumnCurrentValue, subColumnField} = item.options || {}

        const group = settings?.tableGroups?.find(g => g.uuid === item.group)
        if (item.children && group) {
            return [{
                title: group.label,
                children: this.buildViewItems(item.children, false)
                    .map(child => this.buildColumn({
                        ...child,
                        columnColor: child.columnColor || group.color || item.columnColor
                    }, child.fieldObject, false)[0]),
                className: group.color || item.columnColor
            }]
        }

        const columnColor = (item.columnColor || group?.color) || ''
        return [{
            title: noLabel ? '' : (item.options?.label || field.label || field.name),
            key: field.name,
            className: columnColor + (noLineBreak ? ' text-nowrap' : '') + (first && standalone && ' pl-4'),
            dataIndex: field.name + (item.options?.subColumnCurrentValue || ''),
            sorter: item.sortable && !ViewTableItemsSettings.isSortableDisabled(field) ? defaultSort ? {multiple: defaultSort.priority} : true : false,
            defaultSortOrder: defaultSort && (defaultSort.direction === 'ASC' ? 'ascend' : 'descend'),
            render: (_: any, row: IRestResource) => {
                let parsedRow = {...row}
                if (subColumnCurrentValue && subColumnField) {
                    const found = row[field.name]?.find((r: any) => r[subColumnField].uuid === subColumnCurrentValue)
                    parsedRow[field.name] = found ? [found] : []
                }
                return this.buildTableCell(item, parsedRow);
            },
            ...FilterBuilder.build(
                item,
                field,
                Object.values(filters).filter(filter =>
                    !isFilterNested(filter) && filter.field.indexOf(field.name) === 0
                    && filter.value !== undefined
                ).map((filter: any) => ({...filter, name: filter.field}) as IFilterBaseValue),
                (value): void => {
                    value.length.toString()
                }, //TODO
                this.state.loading ? [] : results
                    .map(row => this.buildColumnStringValue(item, row)),
                undefined,
                filters
            ),
            ...(Object.entries(filters).length === 0 ? {filteredValue: []} : {}),
        }]
    }

    buildViewItems(settingsItems?: IViewTableItemSettings[], addItemIfNotInSettings = true) {
        const {viewUnit, settings} = this.props
        let items: Column[] = []
        if (settingsItems && settingsItems.length > 0) {
            items = []
            settingsItems.forEach((item) => {
                let viewItems = viewUnit.items.filter(viewItem => viewItem.field === item.fieldId)
                viewItems.forEach(viewItem => {
                    const fieldObject = viewItem && this.findField(viewItem);
                    if (viewItem && fieldObject) {
                        items.push({...viewItem, ...item, fieldObject})
                    }
                })
            })
            if (addItemIfNotInSettings) {
                viewUnit.items.forEach(item => {
                    if (!items.find(i => i.fieldId === item.field)) {
                        items.push({...item, fieldObject: this.findField(item)})
                    }
                })
                items = ViewTableItemsSettings.buildChildren(items, viewUnit.items, settings.tableGroups || []) as Column[]
            }
        } else {
            addItemIfNotInSettings && viewUnit.items.forEach(item => {
                items.push({...item, fieldObject: this.findField(item)})
            })
        }

        return items.filter(item => item.enabled)
    }

    findField(item: IViewItem) {
        let service: IRepositoryService = this.getService()
        return _.findWhere(service.getFields(), {uuid: item.field})!
    }

    getService() {
        const {findServiceByContentType} = this.props
        return findServiceByContentType(this.getContentType());
    }

    buildTableCell(item: Column, row: IRestResource) {
        const field = item.fieldObject
        const {minWidth, maxWidth, noLineBreak, columnValueAlign} = item.options || {}
        let children: any = this.buildColumnValue(field, item, row)
        let style: React.CSSProperties = {}
        let className: string = ''
        switch (field.type) {
            case FIELD_TYPE.SPECIALIZED_COLOR:
                style.backgroundColor = row[field.name]
                children = null
        }
        if (minWidth) {
            style.minWidth = minWidth + "px"
        }
        if (maxWidth) {
            style.maxWidth = maxWidth + "px"
        }
        if (noLineBreak) {
            style.whiteSpace = 'nowrap'
            style.overflow = 'auto'
            className = className + ' minimalist-scroll-bar'
        }
        if (columnValueAlign) {
            className = className + ' ' + columnValueAlign
        }
        return {
            props: {
                style: style,
                className: className,
            },
            children
        }
    }

    getDefaultSort(view: IViewUnit, contentType: IContentType, field: IField) {
        const {order} = this.state
        const {viewUnit} = this.props
        if (Object.keys(order).length > 0) {
            const index = Object.entries(order)
                .findIndex(order => Object.entries(ViewUnit.getItemSortBy(viewUnit, field))
                    .find(([, item]) => order[1].field === item.field))

            return index > -1 ? {priority: index + 1, ...Object.entries(order)[index][1]} : undefined
        }

        return undefined
    }

    onChange = (
        pagination: TablePaginationConfig,
        filters?: Record<string, any | null>,
        sorter?: SorterResult<any> | SorterResult<any>[]
    ) => {
        this.setState(_state => {
            return {
                pageSize: pagination.pageSize || this.state.pageSize,
                page: pagination.current || this.state.page,
                order: this.formatOrder(sorter || []),
                filters: filters ? this.formatFilters(filters) : {}
            }
        }, () => {
            this.saveState()
            this.load().then()
        })
    }

    saveState() {
        const {settings, saveState, state} = this.props
        const {page, order, filters} = this.state
        let newState = {...state}
        if (settings.tablePageSave) {
            newState.tablePage = page
        }
        if (settings.tableSortSave) {
            newState.tableSort = Object.entries(order).map(orderItem => orderItem[1])
        }
        if (settings.tableFilterSave) {
            newState.tableFilter = Object.entries(filters).map(filterItem => filterItem[1])
        }
        saveState({...newState}).then()
    }

    formatOrder(sorter: SorterResult<any> | SorterResult<any>[]) {
        const {viewUnit} = this.props
        if (Object.keys(sorter).length === 0) {
            return {}
        }
        let orders: IRestServiceOrders = {};
        (Array.isArray(sorter) ? sorter : [sorter])
            .forEach((sort: SorterResult<any>) => {
                const field = _.findWhere(this.getService().getFields(), {name: sort.field as string})
                if (field) {
                    orders = {...orders, ...ViewUnit.getItemSortBy(viewUnit, field, sort.order === 'ascend' ? 'ASC' : 'DESC')}
                }
            })
        return orders;
    }

    formatFilters = (filters: Record<string, IFilterBaseValue[] | null>) => {
        const output: IRestServiceFilters = {}
        Object.keys(filters).forEach(name => {
            const filterList = filters[name]
            if (filters.hasOwnProperty(name) && filterList) {
                filterList.forEach((filterItem, index) => {
                    output[name + index] = {
                        field: filterItem.name || name,
                        type: filterItem.type,
                        value: filterItem.value,
                        label: filterItem.label
                    }
                })
            }
        })
        return output
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]}, this.load)
    }

    executeAction = (executeAction: ActionButtonExecuteType, viewAction: IViewAction, resources?: IRestResource[], nested: boolean = false) => {
        const {inlineEditedResources} = this.state
        const {viewUnit, extractRouteParametersFromUrl, history} = this.props
        const contentAction = this.getContentType().actions.find(a => a.uuid === viewAction.action)
        if (inlineEditedResources?.length > 0 && contentAction?.type !== 'table_form' && viewUnit.options.saveInlineEditingGloballyWarning !== false && !nested) {
            return new Promise<void>(resolve => {
                Modal.confirm({
                    title: viewUnit.options.saveInlineEditingGloballyWarningText,
                    okText: 'Pokračovat',
                    okType: 'danger',
                    cancelText: 'Zrušit',
                    onOk: () => this.executeAction(executeAction, viewAction, resources, true).then(resolve)
                })
            })
        }
        this.setState({loading: true})
        return executeAction({
            ...(inlineEditedResources.length > 0 && contentAction?.type === 'table_form' && {
                data: inlineEditedResources,
                submit: "submit"
            }),
            route: extractRouteParametersFromUrl(history.location.pathname)
        }, undefined, resources || inlineEditedResources)
            .catch(() => this.setState({loading: false}))
    }

    getSelectedResources(selectedRowKeys: Key[]): IRestResource[] {
        return selectedRowKeys.map(key => {
            let resource = this.state.results.find(resource => resource.uuid === key)
            if (!resource) {
                resource = this.state.selectedResources.find(r => r.uuid === key)
            }
            if (!resource) {
                throw new Error('Unable to find resource')
            }
            return resource
        })
    }

    render() {
        const {allowExport, settings, viewUnit, history, match, standalone, insideTab, permissions} = this.props
        const {
            results,
            loading,
            page,
            pageSize,
            count,
            totalCount,
            selectedRowKeys,
            filters,
            order,
            selectedResources,
            inlineEditedResources,
            aggregations,
            screen
        } = this.state;
        const rowSelection = {
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: this.onSelectRow.bind(this),
        };
        const tableColumns = this.buildColumns()
        const allowedCollectionActions = ViewUnitsService.getAllowedCollectionActions(viewUnit, permissions, this.getContentType())
        const isMobile = screen.isMobile

        let className = ''
        if (settings.tableHeaderSticky === false) {
            className += ' overflow-hidden'
        } else if (!loading) {
            className += ' sticky-header'
        }
        if (standalone) {
            className += ' border-top border-bottom'
        }

        return (
            <div className={'position-relative'}>
                <Prompt
                    when={inlineEditedResources?.length > 0 && viewUnit.options.saveInlineEditingGloballyWarning !== false}
                    message={viewUnit.options.saveInlineEditingGloballyWarningText || ''}/>
                <ViewTitle hideTitle={insideTab || !standalone} viewUnit={viewUnit} marginBottom={3}>
                    <Row className={"noPrint"} gutter={[4, 6]}>
                        <ViewSchemeActions onFinish={this.onFinish} contentType={this.getContentType()}
                                           onClick={this.executeAction}
                                           permissions={permissions} viewUnit={viewUnit} match={match}
                                           history={history}/>
                        <Col>
                            <Tooltip title={"Znovu načíst data"}>
                                <Button
                                    onClick={() => this.reload()}
                                    style={{verticalAlign: "middle"}}
                                    icon={<RefreshOutline/>}
                                    size={"small"}
                                />
                            </Tooltip>
                        </Col>
                        <ViewPersonalEditButton {...this.props} />
                        {(typeof allowExport === 'undefined' ? true : allowExport) && (
                            <Col>
                                <ViewTableExportButton viewUnit={viewUnit} totalCount={count} settings={settings}
                                                       options={{filters: this.getFilters(), order}}/>
                            </Col>
                        )}
                        <ViewEditButton {...this.props} />
                        <Col
                            className={"d-flex align-content-center align-self-center align-items-center  text-nowrap"}>
                            <ViewFiltersInfo loading={loading}
                                             getFieldTitle={name => tableColumns.find(column => column.key === name)?.title}
                                             onReset={this.clearFilters} filters={filters} total={totalCount}
                                             count={count}/>
                        </Col>
                    </Row>
                </ViewTitle>

                <div>
                    {settings.customFilters && (
                        <div className={'mb-2'}>
                            <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                        </div>
                    )}
                    {this.renderCollectionActions(allowedCollectionActions, selectedRowKeys, selectedResources, true)}
                </div>
                <Aggregations match={match} history={history} contentType={this.getContentType()}
                              aggregations={aggregations} options={viewUnit.options?.aggregations}/>

                <Table
                    className={className}
                    style={standalone ? {
                        marginLeft: isMobile ? '-1rem' : '-1.5rem',
                        marginRight: isMobile ? '-1rem' : '-1.5rem',
                        maxWidth: "initial"
                    } : {}}
                    loading={loading}
                    rowSelection={allowedCollectionActions.length > 0 ? rowSelection : undefined}
                    columns={tableColumns}
                    dataSource={results}
                    pagination={false}
                    rowKey={row => row.uuid}
                    onChange={this.onChange}
                />

                <Row justify={"space-between"} className={'mt-2'} gutter={[8, 8]}>
                    <Col>
                        {allowedCollectionActions.filter(value => value.weight >= 1).length > 0 ?
                            this.renderCollectionActions(allowedCollectionActions, selectedRowKeys, selectedResources) :
                            ''
                        }
                    </Col>
                    <Col>
                        <Pagination {...{
                            className: 'text-center',
                            current: page,
                            total: count,
                            pageSize,
                            onChange: (page, pageSize) => this.onChange({current: page, pageSize}),
                            hideOnSinglePage: !viewUnit.options.allowPageSizeChanger,
                            showSizeChanger: viewUnit.options.allowPageSizeChanger
                        }}/>
                    </Col>

                </Row>
            </div>
        )
    }

    renderCollectionActions = (allowedCollectionActions: IViewAction[],
                               selectedRowKeys: React.Key[],
                               selectedResources: IRestResource[],
                               top: boolean = false
    ) => {
        const {match, history} = this.props
        return allowedCollectionActions.filter(value => top ? value.weight < 1 : value.weight >= 1).length > 0 && (
            <Row className={"noPrint" + (top ? ' py-2' : 'py-1')} gutter={[6, 6]}>
                <Col className={"align-items-center d-flex"}>
                    Vybráno: {selectedRowKeys.length}
                </Col>
                <Col>
                    <Row gutter={[6, 6]}>
                        {allowedCollectionActions.filter(value => top ? value.weight < 1 : value.weight >= 1).map(action => {
                            return (
                                <Col key={action.uuid}>
                                    <ViewAction
                                        history={history}
                                        match={match}
                                        disabled={selectedRowKeys.length === 0}
                                        onClick={executeAction => this.executeAction(executeAction, action, selectedResources)}
                                        action={action}
                                        onFinish={this.onFinish}
                                        resources={selectedResources}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        );
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(state, url),
        user
    }
}

export default connect(mapStateToProps)(ViewTable)