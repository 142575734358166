import React, {RefObject} from "react";
import {Alert, Col, DatePicker, Form, FormInstance, Input, Radio, Row, Select, Spin, TimePicker} from "antd";
import IType from "../../../../../model/interface/company/workload/IType";
import TypesService from "../../../../../model/service/company/workload/TypesService";
import IPlan from "../../../../../model/interface/company/workload/IPlan";
import moment, {Moment} from "moment"
import IEmployee from "../../../../../model/interface/company/IEmployee";
import IShift from "../../../../../model/interface/company/IShift";
import PlansService from "../../../../../model/service/company/workload/PlansService";
import FilePicker from "../../../../shared/pickers/file/FilePicker";
import LocaleProvider from "../../../../../i18n/LocaleProvider";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import IClaim from "../../../../../model/interface/company/workload/IClaim";
import FormFieldEmployee from "../../../configuration/form/FormElement/formField/FormFieldEmployee";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import ILabelValue from "../../../../../model/interface/util/ILabelValue";
import Utils from "../../../../../utils";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import {COMPANY_WORKLOAD_FUND_PERIOD_YEAR} from "../../../../../model/interface/company/workload/IFund";
import {humanShiftHoursInDays, humanTime} from "../../../file-manager/utils/TimeDuration";
import PlanEmployeeChoicesService from "../../../../../model/service/company/workload/PlanEmployeeChoicesService";
import {IAppState} from "../../../../../redux/store";
import {connect} from "react-redux";
import IUser from "../../../../../model/interface/security/IUser";
import ShiftsService from "../../../../../model/service/company/workload/ShiftsService";
import IObligation from "../../../../../model/interface/company/IObligation";
import UsersService from "../../../../../model/service/security/UsersService";
import selectors from "../../../../../redux/selectors";
import {VACATION_RESTRICT_ABSENCE_SPLIT} from "../../../../../model/interface/security/ICredential";

interface IProps {
    formRef: RefObject<FormInstance>,
    resource: IPlan,
    choices?: ILabelValue[]
    onChange?: (values: any) => void,
    onLoadPreview?: (plan: IPlan) => void
    format?: string
    employee?: string,
    types?: IType[]
    user: IUser
    getText: (code: string, fallBack?: string) => string
}

interface IState {
    types: IType[],
    loadingSetup: boolean,
    loadingPreview: boolean,
    loadingEmployee: boolean,
    loading: boolean,
    format: string
    formValues: any
    employee: IEmployee | null,
    type: IType | null
    preview: IPlan | null
    employeeChoices: ILabelValue[]
}

const DURATION = {
    SINGLE: 'single',
    MULTIPLE: 'multiple'
}

const INTERVAL_TYPE = {
    DAY: 'day',
    CUSTOM: 'custom'
}

class RequestForm extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            types: props.types ? [...TypesService.listPlanType(props.types)] : [],
            loadingSetup: false,
            loadingPreview: false,
            loadingEmployee: false,
            loading: false,
            preview: props.resource.id ? props.resource : null,
            type: (props.resource && props.resource.type) || null,
            employee: null,
            format: props.format || 'D.M.YYYY HH:mm',
            formValues: this.buildFormValues(props),
            employeeChoices: [...(props.choices || [])]
        }
    }

    componentDidMount() {
        this.loadSetup()
    }

    loadSetup = () => {
        const {types, employeeChoices} = this.state
        this.setState({loading: true}, () => {
            const promises = []
            if (types.length === 0) {
                promises.push(this.loadTypes())
            }
            if (employeeChoices.length === 0) {
                promises.push(this.loadEmployees())
            }
            promises.push(this.loadEmployee())
            Promise.all(promises).then(() => {
                this.setState({loading: false}, this.props.formRef.current?.resetFields)
            })
        })
    }

    loadTypes() {
        return TypesService.collectionListPlanType({
            limit: 0
        }).then(response => {
            this.setState({types: response.results})
        });
    }

    loadEmployees() {
        const {user} = this.props
        return PlanEmployeeChoicesService.get(user).then((employeeChoices) =>
            new Promise<void>(resolve => {
                this.setState({employeeChoices}, resolve)
            }))
    }

    loadEmployee() {
        const {formValues} = this.state
        const {resource} = this.props
        if (resource.id && resource.employee) {
            return new Promise<void>(resolve => {
                this.setState({employee: resource.employee}, resolve)
            })
        } else if (formValues.employee) {
            return new Promise<void>(resolve => {
                this.setState({loading: true},
                    () => EmployeesService.getInstance().resourceRetrieve(formValues.employee)
                        .then(employee => this.setState({employee, loading: false}, resolve)))
            })
        } else {
            return new Promise<void>(resolve => {
                this.setState({employee: null}, resolve)
            })
        }
    }

    onValuesChange = (values: any) => {
        const {formRef, onChange, onLoadPreview, resource} = this.props
        new Promise<void>(resolve => {
            // save form values
            values = this.parseValues(values)
            const {endAt, beginAt} = this.prepareDates({...this.state.formValues, ...values})
            const preparedValues = {
                ...values,
                beginAtDate: beginAt,
                endAtDate: endAt,
                endAt: endAt?.format('YYYY-MM-DD HH:mm:ss'),
                beginAt: beginAt?.format('YYYY-MM-DD HH:mm:ss'),
            }
            this.setState(state => ({loading: true, formValues: {...state.formValues, ...preparedValues}}), () => {
                this.hasDependentFieldsChanged(values) && formRef.current?.resetFields()
                resolve()
            })
        })
            .then(() => {
                // resolve employee
                return new Promise<void>(resolve => {
                    if (this.state.formValues.employee) {
                        if (!this.state.employee || this.state.employee?.uuid !== this.state.formValues.employee) {
                            return EmployeesService.getInstance().resourceRetrieve(this.state.formValues.employee).then(employee => {
                                this.setState({employee}, resolve)
                            })
                        } else {
                            resolve()
                        }
                    } else {
                        this.setState({employee: null}, resolve)
                    }
                })
            })
            .then(() => {
                // get type from employee object
                return new Promise<void>(resolve => {
                    let type = null
                    if (this.state.formValues.type && this.state.employee) {
                        type = this.state.types.find(_type => _type.uuid === this.state.formValues.type) || null
                    }
                    this.setState({type}, resolve)
                })
            })
            .then(() => {
                // if all is set, fetch preview
                return new Promise<void>(resolve => {
                    const {employee, type, formValues} = this.state
                    if (formValues.beginAtDate && formValues.endAtDate && employee && type) {
                        const data = {
                            employee: employee ? employee.uuid : null,
                            type: type ? type.uuid : null,
                            beginAt: formValues.beginAtDate.format('YYYY-MM-DD HH:mm:ss'),
                            endAt: formValues.endAtDate.format('YYYY-MM-DD HH:mm:ss')
                        }
                        return this.hasDependentFieldsChanged(values) ?
                            PlansService.collectionCreatePreview({...data, id: resource.id}).then(preview => {
                                onLoadPreview?.(preview)
                                this.setState({preview}, () => {
                                    formRef.current?.validateFields()
                                    resolve()
                                })
                            }) : resolve()
                    } else {
                        this.setState({preview: null}, resolve)
                    }
                })
            })
            .finally(() => {
                onChange?.(values)
                return new Promise<void>(resolve => this.setState({loading: false}, resolve))
            })
    }

    parseValues(values: any) {
        const {formRef} = this.props
        const {formValues} = this.state
        if (values.beginAtType === INTERVAL_TYPE.CUSTOM && formValues.duration === DURATION.SINGLE) {
            values.beginAtTime = [
                formValues.beginAtTime?.[0] || formValues.beginAtDate,
                formValues.beginAtTime?.[1] || formValues.endAtDate
            ]
            values.endAtType = values.beginAtType
        }
        if (Object.keys(values).includes('duration')) {
            if (Array.isArray(formValues.beginAtTime)) {
                values.beginAtTime = formValues.beginAtTime[0]
                values.endAtTime = formValues.beginAtTime[1]
                const types = this.detectBeginEndType(...Object.values(this.prepareDates({...formValues, ...values})))
                values = {...values, ...types}
            } else if (formValues.beginAtTime && formValues.endAtTime) {
                values.beginAtTime = [
                    moment.min(formValues.beginAtTime, formValues.endAtTime),
                    moment.max(formValues.beginAtTime, formValues.endAtTime)
                ]
            }
            formRef.current?.setFieldsValue({beginAtTime: undefined})
        }
        return values
    }

    hasDependentFieldsChanged(values: any) {
        return Object.keys(values).some(key =>
            ['beginAtType', 'endAtType', 'employee', 'type', 'duration',
                'beginAtDate', 'beginAtTime', 'endAtDate', 'endAtTime'].includes(key)
        )
    }

    prepareDates(formValues: any) {
        const {employee} = this.state
        const {duration, beginAtDate, beginAtType, beginAtTime, endAtDate, endAtType, endAtTime} = formValues

        let beginAt: Moment | undefined
        let endAt: Moment | undefined
        const shifts = employee?.obligation?.shifts
        if (duration === DURATION.SINGLE) {
            if (beginAtDate) {
                let from, to
                switch (beginAtType) {
                    case(INTERVAL_TYPE.DAY):
                        if (shifts?.length) {
                            from = shifts[0].startAtTime
                            to = shifts[shifts?.length - 1]?.endAtTime
                        }
                        break;
                    case(INTERVAL_TYPE.CUSTOM):
                        from = beginAtTime?.[0]?.format('HH:mm') || shifts?.[0]?.startAtTime
                        to = beginAtTime?.[1]?.format('HH:mm') || shifts?.[shifts?.length - 1]?.endAtTime || beginAtTime?.[0]?.format('HH:mm')

                        break;
                    default:
                        const shift = shifts?.find(_shift => beginAtType === _shift.uuid)
                        if (shift) {
                            from = shift.startAtTime
                            to = shift.endAtTime
                        }
                }
                if (from && to) {
                    beginAt = moment(beginAtDate?.format('YYYY-MM-DD') + ' ' + from, 'YYYY-MM-DD HH:mm')
                    endAt = moment(beginAtDate?.format('YYYY-MM-DD') + ' ' + to, 'YYYY-MM-DD HH:mm')
                }
            }
        } else {
            if (beginAtDate && endAtDate) {
                beginAt = this.buildMoment(shifts!, true, beginAtDate, beginAtType, beginAtTime)
                endAt = this.buildMoment(shifts!, false, endAtDate.isSameOrBefore(beginAtDate, 'd') ? beginAtDate.clone().add(1, 'd') : endAtDate, endAtType, endAtTime)
            }
        }

        return {
            beginAt: beginAt || beginAtDate,
            endAt: endAt || beginAtDate
        }
    }

    buildMoment = (shifts: IShift[], shiftStart: boolean, date: Moment, type: string, customTime?: Moment) => {
        let time
        switch (type) {
            case(INTERVAL_TYPE.DAY):
                if (shiftStart) {
                    time = shifts[0]?.startAtTime || 0
                } else {
                    time = shifts[shifts?.length - 1]?.endAtTime || 0
                }
                break;
            case(INTERVAL_TYPE.CUSTOM):
                time = customTime?.format('HH:mm')
                    || (shifts.length && (shiftStart ? shifts[0].startAtTime : time = shifts[shifts?.length - 1].endAtTime)) || 0
                break;
            default:
                const shift = shifts?.find(_shift => type === _shift.uuid)
                if (shift) {
                    time = shiftStart ? shift.startAtTime : shift.endAtTime
                }
        }
        return time ? moment(date?.format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm') : undefined
    }

    getShifts(date?: Moment): IShift[] {
        const employee = this.state?.employee || this.props.resource.employee
        if (employee) {
            const obligation = employee.obligation
            if (obligation) {
                return obligation.shifts.filter(s => !date || ShiftsService.isShiftActive(s, date, employee)).sort((a, b) => a.startAtTimeHours! - b.startAtTimeHours!)
            }
        }
        return []
    }

    getMaxShiftHours(): number {
        const employee = this.state?.employee || this.props.resource.employee
        if (employee) {
            const obligation = employee.obligation
            if (obligation) {
                return Math.max(...obligation.shifts.map(s => s.totalHours))
            }
        }
        return 0
    }

    getObligation(): IObligation {
        const employee = this.state?.employee || this.props.resource.employee
        if (employee?.obligation) {
            return employee.obligation
        }
        throw new Error('Unable to retrieve obligation')
    }

    getDuration(): string {
        return this.state.formValues.duration
    }

    getBeginType(): string {
        return this.state.formValues.beginAtType
    }

    getEndType(): string {
        return this.state.formValues.endAtType
    }

    getDisabledHours(intervals: { from: Moment, to: Moment }[]) {
        const disabled: number[] = []
        intervals.length && Array(24).fill(null).forEach((_, index) => {
            let exists = false
            intervals.forEach(interval => {
                if (interval.from?.clone().hours(index).isBetween(interval.from, interval.to, undefined, '[]')) {
                    exists = true
                }
            })
            if (!exists) {
                disabled.push(index)
            }
        })
        return disabled
    }

    getDisabledMinutes(intervals: { from: Moment, to: Moment }[], hour: number) {
        const disabled: number[] = []
        intervals.length && Array(60).fill(null).forEach((value, index) => {
            let exists = false
            intervals.forEach(interval => {
                if (interval.from?.clone().hours(hour).minute(index).isBetween(interval.from, interval.to, undefined, '[]')) {
                    exists = true
                }
            })
            if (!exists) {
                disabled.push(index)
            }
        })
        return disabled
    }

    getTypeClaimOptions(date?: Moment) {
        const employee = this.state.employee
        let output: {
            type: IType,
            claim: IClaim | undefined,
            available: boolean,
            dateNeeded?: boolean,
            extendsClaim?: IClaim
        }[] = []
        if (employee) {
            this.state.types.forEach(type => {
                const claim = this.state.employee!.workloadClaims!.find(claim =>
                    claim.type.uuid === type.uuid
                    && (!date || date.isBetween(MomentBuilder.build(claim.validFrom), MomentBuilder.build(claim.validUntil), null, '[]'))
                )
                const dateNeeded = claim?.fund.period !== COMPANY_WORKLOAD_FUND_PERIOD_YEAR && !date && !type.unlimitedFund
                let available = type.unlimitedFund || (claim && claim.amountFree > 0) || dateNeeded
                let extendsClaim
                if (claim?.fund.extends) {
                    extendsClaim = this.state.employee!.workloadClaims!.find(c =>
                        c.fund.uuid === claim?.fund.extends
                        && (!date || date.isBetween(MomentBuilder.build(c.validFrom), MomentBuilder.build(c.validUntil), null, '[]'))
                    )
                    available = available && (extendsClaim?.type.unlimitedFund || (!!extendsClaim && extendsClaim.amountFree === 0))
                }
                output.push({type, claim, available, dateNeeded, extendsClaim})
            })
        }
        return output.sort((a) => a.available || a.dateNeeded ? -1 : 1)
    }

    render() {
        const {loading, employee, type, formValues, preview, employeeChoices} = this.state
        const {formRef, resource} = this.props
        let free = this.getFreeAmount(preview, resource);
        const insufficientClaim = preview ? ((preview.total || 0) > (free || 0) && !preview.type?.unlimitedFund) : false
        const representativeCollision = !!(preview?.representativeCollisions?.filter(p => p.state === 'approved').length)
        const selfCollision = !!(preview?.selfCollisions?.length)

        const intervals = this.getShifts()
            .map(shift => ({
                from: moment(shift.startAtTime, 'HH:mm'),
                to: moment(shift.endAtTime, 'HH:mm')
            }))

        return (
            <>
                <Form ref={formRef} initialValues={formValues} onValuesChange={(values) => this.onValuesChange(values)}>
                    <h2 className={"font-size-lg"}>Žádost o nepřítomnost</h2>
                    <Spin spinning={loading}>
                        <Form.Item name={"beginAt"} hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"endAt"} hidden>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Zaměstnanec"}
                            name={"employee"}
                            rules={[{required: true, message: 'Povinná položka'}]}
                        >
                            <Select options={employeeChoices} optionFilterProp="children" showSearch
                                    filterOption={(input, option) =>
                                        Utils.stringContains(option?.label, input)
                                    }/>
                        </Form.Item>

                        {employee && employee!.workloadClaims!.length > 0 && (
                            <Form.Item
                                label={"Typ"}
                                name={"type"}
                                rules={[{
                                    required: true,
                                    message: 'Povinná položka',
                                }, this.validateClaim(insufficientClaim)]}
                            >
                                <Select>
                                    {this.getTypeClaimOptions(formValues.beginAtDate).map(({
                                                                                               type,
                                                                                               claim,
                                                                                               available,
                                                                                               dateNeeded,
                                                                                               extendsClaim
                                                                                           }) => {
                                        const totalShiftsHours = this.getObligation().dayWorkHours || 0

                                        return (
                                            <Select.Option value={type.uuid} key={type.uuid} disabled={!available}>
                                                <strong>{type.title}</strong> {!type?.unlimitedFund && !dateNeeded && <>zůstatek: {humanTime(claim ? claim.amountFree : 0, 'hour', ['hour', '?minute'])}
                                                {(claim?.amountFree || 0) > totalShiftsHours ? ' (' + humanShiftHoursInDays(claim ? claim.amountFree : 0, totalShiftsHours) + ')' : ''}</>}
                                                {dateNeeded && 'zůstatek závisí na datu nepřítomnosti'}
                                                {!available && extendsClaim && ' , nelze čerpat dokud nebude vyčerpáno: ' + extendsClaim.type.title}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )}
                        {!employee ? (
                            <Alert type={"info"} message={"Nejprve zvolte zaměstnance"}/>
                        ) : (
                            employee!.workloadClaims!.length === 0 ? (
                                <Alert type={"warning"}
                                       message={"Zaměstnanec nemá nastavené žádné zůstatky pro nepřítomnost"}/>
                            ) : (
                                !type && (
                                    <Alert type={"info"} message={"Zvolte typ nepřítomnosti"}/>
                                )
                            )
                        )}

                        {employee && type && (
                            <>
                                <Form.Item
                                    label={"Trvání"}
                                    rules={[{required: true, message: 'Povinná položka'}]}
                                    name={"duration"}
                                >
                                    <Radio.Group>
                                        <Radio value={"single"}>Jeden den</Radio>
                                        <Radio value={"multiple"}>Více dní</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {this.getDuration() === 'single' && (
                                    <>
                                        <Form.Item
                                            label={"Datum"}
                                            rules={[{
                                                required: true,
                                                message: 'Povinná položka'
                                            }, this.validateDate()]}
                                            name={"beginAtDate"}
                                        >
                                            <DatePicker
                                                locale={LocaleProvider.getPicker()}
                                                format={'D.M.YYYY'}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={"Čas"}
                                            required={true}
                                            hidden={!this.isCustomIntervalAllowed()}
                                        >
                                            <Row align={'middle'}>
                                                <Form.Item
                                                    name={"beginAtType"}
                                                    noStyle
                                                >
                                                    <Radio.Group>
                                                        {this.getShifts().length && (
                                                            <Radio value={INTERVAL_TYPE.DAY}>Celý den</Radio>
                                                        )}
                                                        {this.isCustomIntervalAllowed() && this.getShifts(formValues.beginAtDate).map((shift) => (
                                                            <Radio key={shift.uuid} value={shift.uuid}>
                                                                {shift.startAtTimeHours}:{String(shift.startAtTimeMinutes).padStart(2, '0')} - {shift.endAtTimeHours}:{String(shift.endAtTimeMinutes).padStart(2, '0')}
                                                            </Radio>
                                                        ))}
                                                        {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                            <Radio value={INTERVAL_TYPE.CUSTOM}>Vlastní</Radio>
                                                        )}
                                                    </Radio.Group>
                                                </Form.Item>
                                                {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                    <Form.Item
                                                        name={"beginAtTime"}
                                                        noStyle
                                                        rules={[{
                                                            required: this.getBeginType() === INTERVAL_TYPE.CUSTOM,
                                                            message: 'Povinná položka'
                                                        }]}
                                                    >
                                                        <TimePicker.RangePicker
                                                            disabled={this.getBeginType() !== INTERVAL_TYPE.CUSTOM}
                                                            minuteStep={15}
                                                            format={this.isCustomIntervalAllowed(['all']) ? "HH:mm" : 'HH'}
                                                            hideDisabledOptions={true}
                                                            disabledHours={() => this.getDisabledHours(intervals)}
                                                            disabledMinutes={h => this.getDisabledMinutes(intervals, h)}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Row>
                                        </Form.Item>
                                    </>
                                )}

                                {this.getDuration() === DURATION.MULTIPLE && (
                                    <>
                                        <Form.Item
                                            label={"Začátek"}
                                            rules={[{required: true, message: 'Povinná položka'}]}
                                        >
                                            <Row align={'middle'}>
                                                <Form.Item name={"beginAtDate"} noStyle
                                                           rules={[{
                                                               required: true,
                                                               message: 'Povinná položka'
                                                           }, this.validateDate()]}>
                                                    <DatePicker
                                                        value={formValues.beginAtDate}
                                                        //disabledDate={date => date.isSameOrAfter(formValues.endAtDate, 'd')}
                                                        locale={LocaleProvider.getPicker()}
                                                        format={'D.M.YYYY'}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>

                                                <Form.Item name={"beginAtType"} noStyle
                                                           hidden={!this.isCustomIntervalAllowed()}>
                                                    <Radio.Group className={'ml-3'}>
                                                        {this.getShifts().length && (
                                                            <Radio value={INTERVAL_TYPE.DAY}>Celý den</Radio>
                                                        )}
                                                        {this.isCustomIntervalAllowed() && this.getShifts(formValues.beginAtDate).slice(1).map((shift) => (
                                                                <Radio key={shift.uuid} value={shift.uuid}>
                                                                    od {shift.startAtTimeHours}:{String(shift.startAtTimeMinutes).padStart(2, '0')}
                                                                </Radio>
                                                            )
                                                        )}
                                                        {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                            <Radio value={INTERVAL_TYPE.CUSTOM}>Vlastní</Radio>
                                                        )}
                                                    </Radio.Group>
                                                </Form.Item>
                                                {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                    <Form.Item
                                                        name={"beginAtTime"}
                                                        noStyle
                                                        rules={[{
                                                            required: this.getBeginType() === INTERVAL_TYPE.CUSTOM,
                                                            message: 'Povinná položka'
                                                        }]}
                                                    >
                                                        <TimePicker
                                                            disabled={this.getBeginType() !== INTERVAL_TYPE.CUSTOM}
                                                            minuteStep={15}
                                                            format={this.isCustomIntervalAllowed(['all']) ? "HH:mm" : 'HH'}
                                                            hideDisabledOptions={true}
                                                            disabledHours={() => this.getDisabledHours(intervals)}
                                                            disabledMinutes={(h) => this.getDisabledMinutes(intervals, h)}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Row>
                                        </Form.Item>

                                        <Form.Item
                                            label={"Konec"}
                                            rules={[{required: true, message: 'Povinná položka'}]}
                                        >
                                            <Row align={'middle'}>
                                                <Form.Item name={"endAtDate"} noStyle
                                                           rules={[{required: true, message: 'Povinná položka'}]}>
                                                    <DatePicker
                                                        value={formValues.endAtDate}
                                                        disabledDate={date => date.isSameOrBefore(formValues.beginAtDate, 'd')}
                                                        locale={LocaleProvider.getPicker()}
                                                        format={'D.M.YYYY'}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                                <Form.Item name={"endAtType"} noStyle
                                                           hidden={!this.isCustomIntervalAllowed()}>
                                                    <Radio.Group className={'ml-3'}>
                                                        <Radio value={INTERVAL_TYPE.DAY}>Celý den</Radio>
                                                        {this.isCustomIntervalAllowed() && this.getShifts(formValues.endAtDate).slice(0, -1).map((shift) => (
                                                            <Radio key={shift.uuid} value={shift.uuid}>
                                                                do {shift.endAtTimeHours}:{String(shift.endAtTimeMinutes).padStart(2, '0')}
                                                            </Radio>
                                                        ))}
                                                        {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                            <Radio value={INTERVAL_TYPE.CUSTOM}>Vlastní</Radio>
                                                        )}
                                                    </Radio.Group>
                                                </Form.Item>
                                                {this.isCustomIntervalAllowed(['hour', 'all']) && (
                                                    <Form.Item
                                                        className={'ml-3'}
                                                        name={"endAtTime"}
                                                        noStyle
                                                        rules={[{
                                                            required: this.getEndType() === INTERVAL_TYPE.CUSTOM,
                                                            message: 'Povinná položka'
                                                        }]}
                                                    >
                                                        <TimePicker
                                                            disabled={this.getEndType() !== INTERVAL_TYPE.CUSTOM}
                                                            minuteStep={15}
                                                            format={this.isCustomIntervalAllowed(['all']) ? "HH:mm" : 'HH'}
                                                            hideDisabledOptions={true}
                                                            disabledHours={() => this.getDisabledHours(intervals)}
                                                            disabledMinutes={(h) => this.getDisabledMinutes(intervals, h)}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Row>
                                        </Form.Item>
                                    </>
                                )}

                                <Form.Item
                                    label={"Poznámka"}
                                    name={"description"}
                                    rules={[{required: type.noteRequired, message: 'Povinná položka'}]}
                                >
                                    <Input.TextArea/>
                                </Form.Item>

                                <Form.Item
                                    label={"Přílohy"}
                                    name={"files"}
                                    rules={[{required: type.attachmentRequired, message: 'Povinná položka'}]}
                                >
                                    <FilePicker
                                        canChoose={false}
                                        multiple={true}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={"Zástup"}
                                    name={"representatives"}
                                >
                                    <FormFieldEmployee
                                        options={{
                                            type: '',
                                            label: '',
                                            showClear: true,
                                            employeeMultiple: true,
                                            employeeHideCurrent: true
                                        }}
                                    />
                                </Form.Item>

                                {preview && (
                                    <Alert
                                        type={preview?.reportClosed || insufficientClaim || representativeCollision || selfCollision ? "error" : "info"}
                                        message={"Sumarizace"}
                                        description={(
                                            <>
                                                {preview.claim && !preview.type?.unlimitedFund && (
                                                    <Row gutter={16} className={"pb-2"}>
                                                        <Col xs={12}>
                                                            Aktuální zůstatek:
                                                        </Col>
                                                        <Col xs={12} className={"font-weight-bold"}>
                                                            {humanTime(free, "hour", ["hour", "?minute"])}
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row gutter={16}>
                                                    <Col xs={12}>
                                                        Celkem zahrnuje:
                                                    </Col>
                                                    <Col xs={12} className={"font-weight-bold"}>
                                                        {humanTime(preview.total || 0, "hour", ["hour", "?minute"])}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}>
                                    </Alert>
                                )}
                            </>
                        )}
                    </Spin>
                </Form>
            </>
        )
    }

    private getFreeAmount(preview: IPlan | null, resource: IPlan) {
        let free = preview?.claim?.amountFree || 0
        if (preview && resource.id) {
            free = free + (preview.total || 0)
        }
        return free;
    }

    isCustomIntervalAllowed = (interval: ('hour' | 'all' | 'shift')[] = ['hour', 'all', 'shift']) => {
        const {preview, type} = this.state
        const {user, resource} = this.props
        const free = this.getFreeAmount(preview, resource)
        if (preview && free !== 0 && free < this.getMaxShiftHours()) {
            return true;
        }
        return !type || UsersService.hasCredential(user, VACATION_RESTRICT_ABSENCE_SPLIT + type.uuid, interval)
    }


    validateClaim(insufficient: boolean) {
        return () => ({
            validator(_: any, value: Moment) {
                if (!value || (value && !insufficient)) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Nedostatečný zůstatek pro zvolený interval'));
            },

        });
    }

    validateDate() {
        const {preview} = this.state
        const {getText} = this.props
        const representativeCollision = !!(preview?.representativeCollisions?.filter(p => p.state === 'approved').length)
        const selfCollision = !!(preview?.selfCollisions?.length)
        return () => ({
            validator(_: any, value: Moment) {
                if (value && representativeCollision) {
                    return Promise.reject(new Error(getText('company.plan.representative.collision.warning', 'Pro vybrané období nemůžete čerpat z důvodu zástupu')));
                }
                if (value && selfCollision) {
                    return Promise.reject(new Error(getText('company.plan.self.collision.warning', 'Na vybrané období již máte existující plán')));
                }
                if (value && preview?.reportClosed) {
                    return Promise.reject(new Error(getText('company.plan.report.closed.warning', 'Na vybrané období již je výkaz práce uzavřený')));
                }
                return Promise.resolve();
            },

        });
    }

    shiftStartAtDate = (shift: IShift, date?: Moment) =>
        ShiftsService.getStartAtDate(shift, date)

    shiftEndAtDate = (shift: IShift, date?: Moment) =>
        ShiftsService.getEndAtDate(shift, date)

    buildFormValues(props: IProps): any {
        const {resource, choices, employee} = props
        const beginAt = MomentBuilder.build(resource.beginAt, DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
        const endAt = MomentBuilder.build(resource.endAt || resource.beginAt, DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
        const duration = !beginAt || !endAt || beginAt.isSame(endAt, 'date') ? DURATION.SINGLE : DURATION.MULTIPLE
        const {beginAtType, endAtType} = this.detectBeginEndType(beginAt, endAt)

        return {
            employee: resource.employee?.uuid || employee || (choices?.length === 1 && choices[0].value),
            type: resource.type ? resource.type.uuid : undefined,
            duration,
            beginAtDate: beginAt,
            beginAtType,
            beginAtTime: beginAtType === INTERVAL_TYPE.CUSTOM ?
                (duration === DURATION.SINGLE ? [beginAt, endAt] : beginAt)
                : (duration === DURATION.SINGLE ? [undefined, undefined] : undefined),
            endAtDate: endAt,
            beginAt,
            endAt,
            endAtType,
            endAtTime: endAtType === INTERVAL_TYPE.CUSTOM ? endAt : undefined,
            representatives: resource.representatives?.map(r => r.uuid)
        }
    }

    detectBeginEndType(beginAt?: Moment, endAt?: Moment) {
        const employee = this.state?.employee || this.props.resource.employee
        let beginAtType = ''
        let endAtType = ''
        const shifts = this.getShifts()
        if (beginAt && endAt && shifts.length) {
            shifts.forEach(shift => {
                if (!beginAtType && this.shiftStartAtDate(shift, beginAt).isSame(beginAt)) {
                    beginAtType = shift.uuid
                }
                if (!endAtType && this.shiftEndAtDate(shift, endAt).isSame(endAt)) {
                    endAtType = shift.uuid
                }
            })

            if (shifts[0].uuid === beginAtType && (shifts[shifts.length - 1].uuid === endAtType || endAt.isAfter(beginAt, 'date'))) {
                beginAtType = INTERVAL_TYPE.DAY
            } else if (!beginAtType) {
                beginAtType = INTERVAL_TYPE.CUSTOM
            }

            if (shifts[shifts.length - 1].uuid === endAtType && (shifts[0].uuid === beginAtType || endAt.isAfter(beginAt, 'date'))) {
                endAtType = INTERVAL_TYPE.DAY
            } else if (!endAtType) {
                endAtType = INTERVAL_TYPE.CUSTOM
            }

            if ([beginAtType, endAtType].includes(INTERVAL_TYPE.CUSTOM) && beginAt.isSame(endAt, 'date')) {
                endAtType = beginAtType = INTERVAL_TYPE.CUSTOM
            }
        } else {
            beginAtType = shifts.length === 0 && employee?.obligation ? INTERVAL_TYPE.CUSTOM : INTERVAL_TYPE.DAY
            endAtType = shifts.length === 0 && employee?.obligation ? INTERVAL_TYPE.CUSTOM : INTERVAL_TYPE.DAY
        }

        return {beginAtType, endAtType}
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.setup.user,
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)

    }
}

export default connect(mapStateToProps)(RequestForm)